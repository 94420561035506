<template>
  <div class="bind-wrap">
    <div
      class="bind-item moka"
      :class="mokaOpenid ? 'bind' : 'unbind'"
      @click="onMoka"
    >
      <a-spin :spinning="mokaLoading">
        <img class="bind-icon" src="@/assets/images/moka-logo.png" alt="Moka" />
        <div class="bind-btn-wrap">
          <a
            v-if="mokaOpenid"
            href="javascript: void(0);"
            class="bind-btn"
            @click="onMokaRemove"
          >
            {{ $t("member.bind.moka.unbind") }}
          </a>
        </div>
        <div class="bind-badge" v-if="mokaOpenid">
          {{ $t("member.bind.moka.binded") }}
        </div>
      </a-spin>
    </div>
    <div class="bind-item beisen">
      <a-spin :spinning="beisenLoading">
        <i class="bind-icon iconfont icon-beisen-logo"></i>
        <div class="bind-btn-wrap">
          <template v-if="beisen">
            <a
              href="javascript: void(0);"
              class="bind-btn"
              @click="onBeisen(true)"
            >
              {{ $t("member.bind.beisen.view") }}
            </a>
          </template>
          <a
            href="javascript: void(0);"
            class="bind-btn"
            @click="onBeisenCreate"
            v-else
          >
            {{ $t("member.bind.beisen.create") }}
          </a>
          <a href="javascript: void(0);" class="bind-btn" @click="onTips(2)">
            {{ $t("member.bind.beisen.help") }}
          </a>
        </div>
      </a-spin>
    </div>
    <a-modal
      :maskClosable="false"
      v-model="beisenVisible"
      :title="$t('member.bind.beisen.view')"
      :width="500"
      :destroyOnClose="true"
    >
      <div class="member-bind-beisen-modal-content">
        <div class="beisen-secret-title">
          {{ $t("member.bind.beisen.account") }}：
        </div>
        <div class="beisen-secret-value">
          <span>{{ account }}</span>
        </div>

        <br />

        <div class="beisen-secret-title">
          {{ $t("member.bind.beisen.secret") }}：
        </div>
        <div class="beisen-secret-value">
          <span>{{ beisen }}</span>
        </div>
      </div>
      <template slot="footer">
        <a-button type="primary" @click="onBeisenCopy">
          {{ $t("member.bind.beisen.copy") }}
        </a-button>
      </template>
    </a-modal>
    <a-modal
      :maskClosable="false"
      v-model="beisenTipsVisible"
      title=""
      :width="650"
      :destroyOnClose="true"
    >
      <img
        ref="beisenTipsRef"
        src="@/assets/images/beisen-bind-tips.png"
        :alt="$t('member.bind.beisen.tips')"
        :title="$t('button.previewBig')"
        width="100%"
        v-viewer="{ navbar: false }"
        @click="onBeisenTipsClick"
      />
      <template slot="footer">
        <a-button type="primary" @click="beisenTipsVisible = false">
          {{ $t("button.close") }}
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { confirmModal } from "@/utils/tools";
import {
  createBeisenSecret,
  delBeisenSecret,
  delMokaBind,
  getBeisenSecret,
  getMokaBind,
  getMokaBindData
} from "@/pages/member/api";
import { showMsg } from "@/utils";

export default {
  name: "Ecology",
  data() {
    return {
      account: this.$store.state.user.info.emailAddress,
      beisen: null,
      beisenVisible: false,
      beisenLoading: false,
      beisenTipsVisible: false,
      mokaLoading: false,
      mokaOpenid: null,
      mokaUrl: null
    };
  },
  methods: {
    // 创建北森密钥
    onBeisenCreate() {
      if (this.beisenLoading) return;
      confirmModal(
        this.$t("member.bind.beisen.createConfirm"),
        "",
        async () => {
          const res = await createBeisenSecret();
          if (res.flag !== 0) {
            showMsg(res);
            this.beisenLoading = false;
            return;
          }
          this.beisen = res.data;
          showMsg({
            flag: 0,
            msg: this.$t("showMsg.success.operation")
          });
          this.beisenLoading = false;
          await this.getBeisenKey();
          this.onBeisen(true);
        },
        () => {
          this.beisenLoading = false;
        }
      );
    },
    // 查看北森密钥
    onBeisen(visible) {
      this.beisenVisible = visible;
    },
    // 移除北森密钥
    onBeisenRemove() {
      if (this.beisenLoading) return;
      confirmModal(
        this.$t("member.bind.beisen.removeConfirm"),
        "",
        async () => {
          const res = await delBeisenSecret();
          if (res.flag !== 0) {
            showMsg(res);
            this.beisenLoading = false;
            return;
          }
          this.beisen = res.data;
          showMsg({
            flag: 0,
            msg: this.$t("showMsg.success.operation")
          });
          this.beisenLoading = false;
          await this.getBeisenKey();
        },
        () => {
          this.beisenLoading = false;
        }
      );
    },
    // 获取北森密钥
    async getBeisenKey() {
      this.beisenLoading = true;
      const res = await getBeisenSecret();
      if (res.flag !== 0) {
        showMsg(res);
        this.beisenLoading = false;
        return;
      }
      this.beisen = res.data;
      this.beisenLoading = false;
    },
    // 北森复制密钥
    onBeisenCopy() {
      navigator.clipboard.writeText(this.beisen);
      showMsg({
        flag: 0,
        msg: this.$t("showMsg.success.copy")
      });
    },
    // 获取moka绑定状态和绑定地址
    async getMokaBindUrl() {
      this.mokaLoading = true;
      const res = await getMokaBind();
      if (res.flag !== 0) {
        showMsg(res);
        this.mokaLoading = false;
        return;
      }
      this.mokaUrl = res.data;
      this.mokaLoading = false;
    },
    // 获取moka绑定状态和绑定地址
    async getMokaBindData() {
      this.mokaLoading = true;
      const res = await getMokaBindData();
      if (res.flag !== 0) {
        showMsg(res);
        this.mokaLoading = false;
        return;
      }
      this.mokaOpenid = res.data?.openId;
      this.mokaLoading = false;
    },
    // 获取moka绑定
    async getMokaBind() {
      await this.getMokaBindUrl();
      await this.getMokaBindData();
    },
    // 解绑moka
    onMokaRemove() {
      if (this.mokaLoading) return;
      confirmModal(
        this.$t("member.bind.beisen.removeConfirm"),
        "",
        async () => {
          const res = await delMokaBind();
          if (res.flag !== 0) {
            showMsg(res);
            this.mokaLoading = false;
            return;
          }
          this.beisen = res.data;
          showMsg({
            flag: 0,
            msg: this.$t("showMsg.success.operation")
          });
          this.mokaLoading = false;
          await this.getMokaBind();
        },
        () => {
          this.mokaLoading = false;
        }
      );
    },
    // moka跳转
    onMoka() {
      // 已绑定不跳转
      if (this.mokaOpenid) return;
      if (!this.mokaUrl) {
        showMsg({
          flag: 1,
          msg: this.$t("member.bind.moka.noUrl")
        });
        return;
      }
      // 未绑定点击跳转
      window.open(this.mokaUrl);
    },
    // 打开操作提示
    onTips(type) {
      if (type === 2) {
        this.beisenTipsVisible = true;
      }
    },
    onBeisenTipsClick() {
      const viewer = this.$refs.beisenTipsRef.$viewer;
      this.$nextTick(() => {
        setTimeout(() => {
          viewer.zoom(3);
          viewer.move(0, 1300);
        }, 900);
      });
    }
  },
  mounted() {
    this.getBeisenKey();
    this.getMokaBind();
  }
};
</script>

<style lang="less" scoped>
.bind-wrap {
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
  padding-left: 20px;
  padding-bottom: 20px;

  .bind-item {
    width: 256px;
    height: 117px;
    border-radius: 8px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
    border: 1px solid #ccd8f0;
    text-align: center;
    position: relative;

    /deep/.ant-spin-nested-loading {
      height: 100%;

      .ant-spin-container {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 8px;
        padding-top: 42px;
        padding-bottom: 12px;
      }
    }

    .bind-icon {
      line-height: 1;
    }

    .bind-btn-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
    }

    .bind-badge {
      font-size: 14px;
      font-weight: 400;
      color: @primary-color;
      text-align: center;
      height: 20px;
      line-height: 18px;
      background: rgba(2, 122, 255, 0.08);
      border-radius: 20px;
      opacity: 1;
      border: 1px solid @primary-color;
      padding: 0 7px;
      position: absolute;
      z-index: 1;
      top: 11px;
      right: 18px;
    }

    .bind-tips {
      position: absolute;
      z-index: 1;
      top: 11px;
      right: 18px;
      font-size: 16px;
      cursor: pointer;
    }

    &.beisen {
      .bind-icon {
        font-size: 25px;
      }
    }

    &.moka {
      &.unbind {
        cursor: pointer;
      }
      .bind-icon {
        width: 125px;
        height: 25px;
      }
    }
  }
}
</style>

<style lang="less">
.member-bind-beisen-modal-content {
  padding: 0 30px;

  .beisen-secret-title {
    font-size: 16px;
  }
  .beisen-secret-value {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    margin-top: 5px;

    .anticon-copy {
      color: @primary-color;
      cursor: pointer;
    }
  }
}
</style>
